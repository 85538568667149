'use client'
import React from "react";

import { Swiper, SwiperSlide } from 'swiper/react';
import LargeSwiperImage from '@components/LargeSwiperImage'
import SmallSwiperImage from '@components/SmallSwiperImage'
import 'swiper/css';


const DistrictCardSlider = ({ title, description, districts, dictionary, lang }) => {
    const districtArray = []
    let activeSmallGroup = null
    // console.log(districts)
    districts.forEach((dis, index) => {
        // console.log(index, activeSmallGroup, dis)
        if (dis.size == 'large' && activeSmallGroup == null) {
            districtArray.push(dis)
        }
        if (dis.size == 'large' && activeSmallGroup !== null) {
            districtArray.push(dis)
            activeSmallGroup = null
        }
        if (dis.size == 'small') {
            if (activeSmallGroup == null) {
                activeSmallGroup = districtArray.length
                districtArray.push({
                    size: 'small',
                    cards: [dis]
                })
            } else if (activeSmallGroup !== null) {
                const lastGroup = districtArray[activeSmallGroup]
                lastGroup?.cards.push(dis)
            }
        }
    })

    // console.log(districtArray)

    return (
        <section className='pl-3 mt-5 w-full'>
            {title !== '' && (
                <h2 className='text-maincolor font-bold text-xl'>{title[lang]}</h2>
            )}
            <Swiper
                spaceBetween={0}
                slidesPerView={"auto"}
                className="mt-2 block sm:hidden"
            >
                {districtArray.map((district, index) => {
                    if (district.size === 'large') {
                        return (
                            <SwiperSlide key={index} className="district-swiper-slide w-[300px]">
                                <LargeSwiperImage location={district.value} type={district.type} title={district.label} imageSrc={district?.label?.replaceAll(' ', '')?.toLowerCase()} />
                            </SwiperSlide>
                        )
                    }
                    if (district.size === 'small') {
                        return (
                            <div key={index}>
                                {district.cards && district.cards.slice(0, Math.ceil(district.cards.length / 2)).map((dis, i) => {
                                    return (
                                        <SwiperSlide key={dis.slug} className="district-swiper-slide">
                                            <SmallSwiperImage
                                                title={district.cards[i * 2]?.subdistrict}
                                                slug={district.cards[i * 2]?.slug}
                                                route='districts'
                                                imageSrc={district.cards[i * 2]?.coverImage}
                                                index={i * 2}
                                            />
                                            {i * 2 + 1 < district.cards.length ? (
                                                <SmallSwiperImage
                                                    title={district.cards[i * 2 + 1]?.subdistrict}
                                                    slug={district.cards[i * 2 + 1]?.slug}
                                                    route='districts'
                                                    imageSrc={district.cards[i * 2 + 1]?.coverImage}
                                                    index={i * 2 + 1}
                                                />
                                            )
                                                : null
                                            }
                                        </SwiperSlide>
                                    )
                                })}
                            </div>
                        )
                    }
                })}
            </Swiper>
        </section>
    )
}

export default DistrictCardSlider