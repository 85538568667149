import React from 'react'
import Image from 'next/image'
import Link from 'next/link'
import LinkComponent from './LinkComponent'
// import '../public/images/bangkok.png'

const LargeSwiperImage = ({ title, imageSrc, location }) => {
    return (
        <LinkComponent href={`/properties?location=${location}`} className='relative district-swiper-large-image w-[150px] h-[300px]'>
            <div className='w-[140px] h-[290px] relative overflow-hidden rounded-md'>
                <Image
                    src={`/images/${imageSrc}.avif`}
                    fill={true}
                    className='object-cover'
                    alt={title}
                // style={{ objectFit: "contain" }}
                />
                <div className='absolute bottom-0 left-0 right-0 rounded-b-md flex justify-center h-[50px] items-start bg-maincolortrans overflow-hidden'>
                    <span className='text-light z-10 px-1 rounded-sm text-center'>{title}</span>
                </div>
            </div>
        </LinkComponent>
    )
}

export default LargeSwiperImage